/*
.front{
  height: 100vh;
  width: 100vw;

  &:hover,
  &:focus{
    .front__item{
      background-color: $color-black;
    }

    .front__item--inverted{
      background-color: $color-white;
    }

    .front__word--post{
      fill: $color-white;
    }

    .front__word--past{
      fill: $color-black;
    }
  }
}

.front__item{
  background-color: $color-white;
  box-sizing: border-box;
  height: 50vh;
  padding-right: 2rem;
  padding-left: 2rem;
  transition: background-color .2s;
  width: 100vw;
}

.front__item--inverted{
  background-color: $color-black;
}

.front__word{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
  transition: fill .2s;
}

.front__word--post{
  fill: $color-black;
}

.front__word--past{
  fill: $color-white;
}

.front__element{
  margin-bottom: 15px;

  &:last-child{
    margin-bottom: 0;
  }

  @media only screen and ("max-width: 767px") {
    width: 100%;
    height: auto;
  }
}
*/
@keyframes scroll {
  100% {
    background-position: 3000px center;
  }
}
@keyframes scroll-reverse {
  100% {
    background-position: -3000px center;
  }
}
.front {
  height: 100vh;
}

.front__line {
  display: block;
  height: 16.6666666667vh;
}

.front__line--post {
  animation: 30s scroll infinite linear;
  background: url("data:image/svg+xml,%3Csvg width='850' height='155' viewBox='0 0 850 155' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M190.588 53.291c0-31.717-26.146-31.717-42.433-31.717H66.717V85.01h81.438c16.287 0 42.433 0 42.433-31.719m25.717 0c0 33.434-23.36 53.15-57.435 53.15H66.717v43.719H41V.143h117.87c34.075 0 57.435 19.716 57.435 53.148M394.18 76.937c0-41.362-22.716-55.506-68.578-55.506s-68.58 13.93-68.58 55.506c0 41.791 22.932 55.935 68.58 55.935 45.648 0 68.579-14.359 68.579-55.935m-162.875 0C231.306 29.146 259.595 0 325.602 0c65.793 0 94.296 29.36 94.296 76.937 0 47.791-28.503 77.366-94.296 77.366-66.007 0-94.296-29.361-94.296-77.366m227.167 24.431c0 22.288 17.573 31.503 76.51 31.503 45.861 0 63.65-6.429 63.65-22.716 0-17.145-11.36-19.93-70.08-23.36-63.436-3.643-89.368-13.287-89.368-46.076C439.185 7.715 473.69 0 527.481 0c55.292 0 90.438 14.144 90.438 50.363h-25.717c0-22.932-19.288-28.933-69.007-28.933-46.506 0-58.293 5.573-58.293 20.574 0 15.86 11.787 19.931 63.65 23.36 59.15 3.858 95.797 6.43 95.797 43.505 0 39.22-40.719 45.434-91.51 45.434-61.508 0-100.083-10.5-100.083-52.935h25.717zm350.395-79.794h-79.295V150.16h-25.717V21.574H624.56V.143h184.307z' fill-rule='evenodd'/%3E%3C/svg%3E") left center repeat-x #fff;
  background-size: 70vh;
}

.front__line--past {
  animation: 30s scroll-reverse infinite linear;
  background: url("data:image/svg+xml,%3Csvg width='850' height='155' viewBox='0 0 850 155' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M190.588 55.292c0-31.718-26.146-31.718-42.433-31.718H66.717V87.01h81.438c16.287 0 42.433 0 42.433-31.718m25.717 0c0 33.433-23.36 53.149-57.435 53.149H66.717v43.719H41V2.144h117.87c34.075 0 57.435 19.716 57.435 53.148m144.354 39.861l-41.147-69.436-41.148 69.436h82.295zm12.645 21.432H265.72l-21.217 35.575H216L304.938 2.143h29.147l88.938 150.017H394.52l-21.216-35.575zm85.413-15.216c0 22.288 17.573 31.503 76.509 31.503 45.862 0 63.65-6.429 63.65-22.717 0-17.144-11.36-19.931-70.08-23.359-63.435-3.644-89.367-13.287-89.367-46.077C439.429 7.716 473.933 0 527.725 0c55.292 0 90.438 14.144 90.438 50.363h-25.717c0-22.932-19.288-28.932-69.007-28.932-46.506 0-58.293 5.572-58.293 20.574 0 15.859 11.787 19.93 63.65 23.359 59.15 3.859 95.797 6.43 95.797 43.505 0 39.219-40.72 45.435-91.51 45.435-61.508 0-100.083-10.502-100.083-52.935h25.717zm349.589-77.795h-79.294V152.16h-25.717V23.574H624V2.144h184.306z' fill-rule='evenodd' fill='%23fff'/%3E%3C/svg%3E") left center repeat-x #000;
  background-size: 70vh;
}